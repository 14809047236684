import Call from "../../../assets/cuffs.png";
import { Head } from "../../../components/Head";

export const Support = () => {
  return (
    <>
      <div className="">
        <Head>Why you should support us</Head>
      </div>
      <div className="py-8">
        <div className="text-sm flex relative h-[48rem] max-w-4xl mx-auto">
          <div className="h-[40rem] w-full md:w-2/3 absolute top-0 left-0 rounded">
            <img
              src={Call}
              alt="..."
              className="h-full object-cover object-center rounded"
            />
          </div>
          <div className="h-auto w-full md:w-2/3 absolute bottom-0 right-0 rounded">
            <div className=" bg-red-600 p-8 space-y-4 text-center font-semibold text-white rounded">
              <p className="text-left">“But what if…” The speaker began.</p>
              <p className="text-left">
                It was a tale of two families at different ends of the spectrum…
              </p>
              <p className="text-left">
                Mr. Dona Akonga was a security man at Akansa Holdings. Life was
                normal until one fateful morning. A detachment of police
                officers arrived at his house. He was wanted for questioning.
                There had been a major burglary at Akansa Holdings. He and his
                fellow security men were prime suspects as it happened during
                their shift.
              </p>
              <p className="text-left">
                They were held while investigations went on. He thought
                everything would be over within a week because he knew he was
                innocent. But contrary to his expectation, a week turned into a
                month, a month into a year and a year into ten years.
              </p>
              <p className="text-left">
                What would have been his maximum sentence if had been found
                guilty of the said burglary?
                <br /> Just 7 years.
              </p>
              <p className="text-left">
                But then he had spent 10 years; released only because the new
                attorney general decided to review a number of cases. He was now
                released. But released with what; into what?
              </p>
              <button className="bg-white py-2 px-4 text-red-600 rounded">
                SUPPORT US
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
