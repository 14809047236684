import React, { useEffect, useState } from 'react'
import { ArrowUp } from "react-feather";

const ScrollToTop = () => {
   const [backToTop, setBackToTop] = useState(false)


      useEffect (() => {
          window.addEventListener("scroll", () => {
            if(window.scrollY > 100){
                setBackToTop(true)
            } else {
                setBackToTop(false)
            }
          })
      }, [])

      const scrollUp = () =>{
        window.scrollTo({
            top:0,
            behavior: "smooth"
        })
      }
    
  return (
    <div className='relative'>
        {backToTop && (
            <button className="absolute mb-50 bottom-28 right-5 h-50 w-50 font-bold text-red-500 rounded-sm bg-slate-200" onClick={scrollUp}>
                <ArrowUp/>
            </button>
        )}
    </div>
  )
}

export default ScrollToTop;