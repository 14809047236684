import Underline from "../assets/underline.png";

export const Head = ({ children }) => {
  return (
    <div className="flex flex-col space-y-2">
      <p className="font-bold">{children}</p>
      <img src={Underline} alt="..." className="w-36 h-2" />
    </div>
  );
};
