import PlaceHolder from "../../../assets/welcom.png";
import { Head } from "../../../components/Head";

export const WelcomeCard = () => {
  return (
    <>
      <div className="space-y-8">
        <Head>Welcome to Prison Fellowship Uganda</Head>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="space-y-3">
            <p>
              Prison Fellowship is a corporate Christian Ministry. It is an
              association of prison ministries of different denominations across
              the Christian faith. Our priorities are to win prison inmates and
              their families to Christ and disciple them in God’s word. Hence we
              exhort, train and equip Christians to reach out and identify with
              prisoners, ex-prisoners and their families, and show them
              practically that the gospel of Jesus christ is able to make the
              difference in their lives.
            </p>
            <button className="px-4 py-2 bg-red-600 text-white rounded text-sm font-bold">
              READ MORE
            </button>
          </div>
          <div className="">
            <img src={PlaceHolder} alt="..." className="shadow-lg" />
          </div>
        </div>
      </div>
    </>
  );
};
