import { BannerCarousel } from "./components/BannerCarousel";
import { MainLayout } from "../../components/layout/MainLayout";
import { CharityCard } from "./components/CharityCard";
import { Stats } from "./components/Stats";
import { Support } from "./components/Support";
import { WelcomeCard } from "./components/WelcomeCard";
import { Project } from "./components/Project";

export const Home = () => {
  return (
    <MainLayout page="home">
      <main>
        <BannerCarousel />
        <div className="space-y-8 pt-8 px-8 lg:px-24">
          <WelcomeCard />
          <Project />
          <Support />
        </div>
        <Stats />
        <div className="my-8 space-y-8 px-8 lg:px-24">
          <CharityCard />
        </div>
      </main>
    </MainLayout>
  );
};
