import { Head } from "../../components/Head";
import { PageHeader } from "../../components/layout/components/PageHeader";
import { MainLayout } from "../../components/layout/MainLayout";

export const About = () => {
  const linksQuery = [
    {
      name: "Statement",
      link: "/page",
    },
    {
      name: "Statement",
      link: "/page",
    },
    {
      name: "Statement",
      link: "/page",
    },
  ];
  return (
    <MainLayout page="about">
      <main>
        <PageHeader title="ABOUT US" />
        <div className="my-8 space-y-8 px-8 lg:px-24">
          <div className="grid grid-cols-12 gap-8">
            <div className="col-span-12 md:col-span-8 space-y-8">
              {/* Welcome message */}
              <div className="space-y-4">
                <Head>Welcome to Prison Fellowship</Head>
                <p>
                  Prison Fellowship is a corporate Christian Ministry. It is an
                  association of prison ministries of different denominations
                  across the Christian faith. Our priorities are to win prison
                  inmates and their families to Christ and disciple them in
                  God’s word. Hence we exhort, train and equip Christians to
                  reach out and identify with prisoners, ex-prisoners and their
                  families, and show them practically that the gospel of Jesus
                  christ is able to make the difference in their lives.{" "}
                </p>
                <p>
                  Prison Fellowship International (PFI), a global movement, was
                  founded in 1976 by Charles Colson, a special counsel to
                  President Nixon, who was convicted in the Water Gate scandal
                  of the Nixon administration in the United States of America.
                  It is the most extensive world movement of volunteers involved
                  in criminal justice field working through 112 member Nations
                  (2008 figure) of which Uganda is one.{" "}
                </p>
                <p>
                  Today, PF Uganda consists of more than hundred member Churches
                  spread across twenty-six districts nation-wide (as at 2015).
                  We exist as a movement of reconciliation and restoration in
                  criminal justice system, proclaiming and demonstrating the
                  redemptive power and transforming love of Jesus Christ for
                  people.
                </p>
              </div>
              {/* Mission message */}
              <div className="space-y-4">
                <Head>Our Mission</Head>
                <p>
                  To be a reconciling community of restoration for all those
                  involved in and affected by crime, thereby proclaiming and
                  demonstrating the redemptive power and transforming love of
                  Jesus Christ for all people
                </p>
              </div>
              <div className="space-y-4">
                <Head>Our Vision</Head>
                <p>
                  To exhort and serve the Body of Christ in prisons and in the
                  community in its ministry to prisoners, ex-prisoners, victims
                  and their families and in its advancement of Biblical
                  standards of justice in the criminal justice system.
                </p>
              </div>
            </div>
            {/* Quick links section */}
            <div className="hidden md:flex col-span-4">
              <div className="w-full">
                <div className="w-full">
                  <div className="space-y-2 ">
                    <p
                      className="rounded-t-lg px-4 py-2 bg-red-600 text-white text-center font-bold
                     text-lg"
                    >
                      Quick links
                    </p>
                    <ul className="space-y-2">
                      {linksQuery.slice(0, 4).map((post, index) => (
                        <li
                          key={index}
                          className=" px-4 py-2 bg-gray-100 shadow-md"
                        >
                          {post.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </MainLayout>
  );
};
