import React from "react";

import carouselItemOne from "../../../assets/prisoners/inmates8.webp";
import carouselItemTwo from "../../../assets/prisoners/inmates.jpeg";
import carouselItemThree from "../../../assets/prisoners/inmate4.jpeg";

export const BannerCarousel = () => {
  return (
    <>
      <div
        id="carouselExampleCaptions"
        className="carousel slide carousel-fade relative"
        data-bs-ride="carousel"
      >
        <div
          className="carousel-indicators absolute right-0 bottom-1/2 flex flex-col justify-center 
        p-0"
        >
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div className="carousel-inner relative h-48 md:h-80 lg:h-[27em] w-full">
          <div className="carousel-item active relative float-left h-full w-full">
            <img
              src={carouselItemOne}
              className=" brightness-75 w-full h-full object-cover"
              alt="..."
            />
          </div>
          <div className="carousel-item relative float-left h-full w-full">
            <img
              src={carouselItemTwo}
              className="brightness-75 w-full h-full object-cover "
              alt="..."
            />
          </div>
          <div className="carousel-item relative float-left h-full w-full">
            <img
              src={carouselItemThree}
              className="brightness-75 w-full h-full object-cover "
              alt="..."
            />
          </div>
        </div>
      </div>
    </>
  );
};
