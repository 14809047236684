import { Footer } from "./components/footer";
import { MediaNav } from "./components/MediaNav";
import { Nav } from "./components/nav";

export const MainLayout = ({ page, children }) => {
  return (
    <>
      <MediaNav />
      <Nav page={page} />
      {children}
      <Footer />
    </>
  );
};
