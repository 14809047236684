import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Home } from "./pages/home";
import { About } from "./pages/about";
import LogoImage from "./assets/logo.png";
import { Blog } from "./pages/blog/Blog";
import { Programs } from "./pages/programs/Programs";
import { Gallery } from "./pages/media/Gallery";

function App() {
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      {Loading ? (
        <div className=" flex flex-row items-center h-[100vh]">
          <img
            src={LogoImage}
            alt="Loading"
            className="h-32 w-auto mx-auto animate__animated animate__flipInx animate-bounce"
          />
        </div>
      ) : (
        <div className="transition ease-in delay-150">
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/blog" element={<Blog />}></Route>
            <Route path="/programs" element={<Programs />}></Route>
            <Route path="/media" element={<Gallery />}></Route>
          </Routes>
        </div>
      )}
    </>
  );
}

export default App;
