import { PageHeader } from "../../components/layout/components/PageHeader";
import { MainLayout } from "../../components/layout/MainLayout";
import Inmate from "../../assets/prisoners/inmates.jpeg";
import Inmate1 from "../../assets/prisoners/inmate6.jpeg";
import Inmate2 from "../../assets/prisoners/inmate5.png";
import Inmate3 from "../../assets/prisoners/inmate4.jpeg";
import Inmate4 from "../../assets/prisoners/inmate3.jpeg";
import Inmate5 from "../../assets/prisoners/inmate2.jpeg";

export const Gallery = () => {
  const galleryList = [
    {
      img_url: Inmate,
      id: 1,
    },
    {
      img_url: Inmate1,
      id: 2,
    },
    {
      img_url: Inmate2,
      id: 3,
    },
    {
      img_url: Inmate3,
      id: 4,
    },
    {
      img_url: Inmate4,
      id: 5,
    },
    {
      img_url: Inmate5,
      id: 6,
    },
  ];

  return (
    <MainLayout page="media">
      <PageHeader title="Gallery" />
      <div className="my-8 space-y-8 px-8 lg:px-24">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {galleryList.map((photo, index) => {
            return (
              <div key={index} className="rounded shadow-lg">
                <img
                  src={photo.img_url}
                  alt="..."
                  className="object-cover object-top h-full w-full rounded"
                />
              </div>
            );
          })}
        </div>
      </div>
    </MainLayout>
  );
};
