import { Link } from "react-router-dom";
import { useState } from "react";
import Proptypes from "prop-types";
import logo from "../../../assets/logo.png";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import Hamburger from "hamburger-react";

export const Nav = ({ page }) => {
  const [open, setOpen] = useState(false);
  const size = "17rem"; //width for drawer

  return (
    <>
      <nav className="flex justify-around items-center font-semibold mx-auto my-5 px-5">
        {/* Logo will come here */}
        <div>
          <Link to="/" className="inline-block">
            <img
              src={logo}
              alt=" "
              className="object-scale-down h-20 w-20 inline-block"
            />
            <p className="my-auto inline-block text-sm">
              Prisons fellowship Uganda
            </p>
          </Link>
        </div>
        {/* Menu items in the center here */}
        <div className="text-sm hidden lg:flex text-center items-center">
          <MenuItems page={page} />
        </div>
        <div className="lg:hidden flex align-right">
          <Hamburger toggled={open} toggle={setOpen} />
          {/* Dropdown */}
          <Drawer
            open={open}
            onClose={() => {
              setOpen((prevState) => !prevState);
            }}
            direction="left"
            style={{ backgroundColor: "white", width: `${size}` }}
          >
            <div className="p-5 space-y-4">
              <MenuItems page={page} />
            </div>
          </Drawer>
        </div>
      </nav>
    </>
  );
};

Nav.propTypes = {
  page: Proptypes.string,
};

const MenuItems = ({ page }) => {
  return (
    <>
      <div>
        <Link
          to="/"
          className={`my-auto ${
            page === "home" ? "font-bold text-red-700 text-sm" : ""
          }`}
        >
          <p className="block my-auto lg:inline-block hover:mb-1 mr-10">HOME</p>
        </Link>
      </div>
      <div>
        <Link
          to="/about"
          className={`my-auto ${
            page === "about" ? "font-bold text-red-700 text-sm" : ""
          }`}
        >
          <p className="block my-auto lg:inline-block hover:mb-1 mr-10">
            WHO WE ARE
          </p>
        </Link>
      </div>
      <div>
        <Link
          to="/programs"
          className={`my-auto ${
            page === "programs" ? "font-bold text-red-700 text-sm" : ""
          }`}
        >
          <p
            href="/"
            className="block my-auto lg:inline-block hover:mb-1 mr-10"
          >
            WHAT WE DO
          </p>
        </Link>
      </div>
      <div>
        <Link
          to="/media"
          className={`my-auto ${
            page === "media" ? "font-bold text-red-700 text-sm" : ""
          }`}
        >
          <p className="block hover:mb-1 my-auto lg:inline-block mr-10">
            MEDIA
          </p>
        </Link>
      </div>
      <div>
        <Link
          to="/blog"
          className={`my-auto ${
            page === "blog" ? "font-bold text-red-700 text-sm" : ""
          }`}
        >
          <p className="block hover:mb-1 my-auto lg:inline-block mr-10">BLOG</p>
        </Link>
      </div>
    </>
  );
};
