import {
  Facebook,
  Instagram,
  Mail,
  Twitter,
  Youtube,
  Voicemail,
} from "react-feather";

export const MediaNav = () => {
  return (
    <div className="bg-red-800 py-2 px-4 lg:px-16 space-y-8">
      <div className="flex flex-col md:flex-row justify-between text-white">
        <div className="flex space-x-4">
          <Mail />
          <p>info@pfu.org</p>
          <Voicemail />
          <p>+256-909-983480</p>
        </div>
        <div className="flex space-x-4">
          <a href="...">
            <Facebook />
          </a>
          <a href="..">
            <Twitter />
          </a>
          <a href="..">
            <Youtube />
          </a>
          <a href="..">
            <Instagram />
          </a>
        </div>
      </div>
    </div>
  );
};
