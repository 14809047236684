import { Head } from "../../../components/Head";
import image from "../../../assets/carousel.png";

export const Project = () => {
  return (
    <div className="space-y-8">
      <Head>Our Programs or Projects</Head>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div
          className="grid grid-cols-1 lg:grid-cols-2 lg:h-64 shadow-lg items-center px-5
        rounded space-x-4"
        >
          <div className="h-32 lg:h-full w-full">
            <img src={image} alt="..." className="object-cover h-full w-full" />
          </div>
          <div className="space-y-4">
            <p className="text-red-700 font-bold">Luzira Prison</p>
            <p>
              As a chartered member of Prison Fellowship International, we are
              committed to achieving a “contemporary expression{" "}
            </p>
            <p className="text-red-700 font-bold">Read more</p>
          </div>
        </div>
        <div
          className="grid grid-cols-1 lg:grid-cols-2 lg:h-64 items-center shadow-lg px-5 
        rounded space-x-4"
        >
          <div className="h-32 lg:h-full w-full">
            <img src={image} alt="..." className="object-cover h-full w-full" />
          </div>
          <div className="space-y-4">
            <p className="text-red-700 font-bold">Ndorwa Prison</p>
            <p>
              Relapse into crime and criminality by ex-prison inmates is largely
              traceable to lack of an appropriate post-release reception and
            </p>
            <p className="text-red-700 font-bold">Read more</p>
          </div>
        </div>
      </div>
    </div>
  );
};
