export const Stats = () => {
  return (
    <div
      className="bg-black py-4 px-24 flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-16 text-white 
    text-center items-center justify-center"
    >
      <div className="flex flex-col space-y-4 text-center">
        <p className="text-6xl font-bold">2000</p>
        <p>Donations received this year</p>
      </div>
      <div className="flex flex-col space-y-4 text-center">
        <p className="text-6xl font-bold">365</p>
        <p>Received Christ this week</p>
      </div>
      <div className="flex flex-col space-y-4 text-center">
        <p className="text-6xl font-bold">246</p>
        <p>Volunteers received this year</p>
      </div>
    </div>
  );
};
