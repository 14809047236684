export const CharityCard = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      <div className="bg-white px-8 py-6 space-y-4 text-center shadow-lg border border-gray-200 rounded">
        <p className="font-bold text-blue-500 text-xl">DONATE</p>
        <p className="text-left">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis
          animi, ad iusto repellendus magnam iste rem, nulla, at veritatis
          facere officiis similique ratione commodi pariatur quae exercitationem
          expedita illum saepe?
        </p>
        <button className="rounded bg-blue-500 py-2 px-4 text-white text-sm font-bold">
          DONATE
        </button>
      </div>
      <div className="bg-white px-8 py-6 space-y-4 text-center shadow-xl border border-gray-200 rounded">
        <p className="font-bold text-red-500 text-xl">Volunteer</p>
        <p className="text-left">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis
          animi, ad iusto repellendus magnam iste rem, nulla, at veritatis
          facere officiis similique ratione commodi pariatur quae exercitationem
          expedita illum saepe?
        </p>
        <button className="rounded bg-red-500 py-2 px-4 text-white text-sm font-bold">
          Volunteer
        </button>
      </div>
    </div>
  );
};
