import { PageHeader } from "../../components/layout/components/PageHeader";
import { MainLayout } from "../../components/layout/MainLayout";
import image from "../../assets/carousel.png";
import { Head } from "../../components/Head";

export const Programs = () => {
  const programList = [
    {
      img_url: image,
      title: "Ndorwa Prison",
      content:
        "Relapse into crime and criminality by ex-prison inmates is largely traceable to lack of an appropriate post-release reception and",
    },
    {
      img_url: image,
      title: "Ndorwa Prison",
      content:
        "Relapse into crime and criminality by ex-prison inmates is largely traceable to lack of an appropriate post-release reception and",
    },
    {
      img_url: image,
      title: "Ndorwa Prison",
      content:
        "Relapse into crime and criminality by ex-prison inmates is largely traceable to lack of an appropriate post-release reception and",
    },
    {
      img_url: image,
      title: "Ndorwa Prison",
      content:
        "Relapse into crime and criminality by ex-prison inmates is largely traceable to lack of an appropriate post-release reception and",
    },
  ];
  return (
    <>
      <MainLayout page="programs">
        <PageHeader title="What we do" />
        <div className="my-8 space-y-8 px-8 lg:px-24">
          <Head>Our Projects and Programs</Head>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {programList.map((program, index) => {
              return (
                <div
                  key={index}
                  className="grid grid-cols-1 lg:grid-cols-2 lg:h-64 items-center shadow-lg px-5 
        rounded space-x-4"
                >
                  <div className="h-32 lg:h-full w-full">
                    <img
                      src={program.img_url}
                      alt="..."
                      className="object-cover h-full w-full"
                    />
                  </div>
                  <div className="space-y-4">
                    <p className="text-red-700 font-bold">{program.title}</p>
                    <p>{program.content}</p>
                    <p className="text-red-700 font-bold">Read more</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </MainLayout>
    </>
  );
};
