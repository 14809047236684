/** @format */

import React, { useEffect, useState } from "react";
import { MainLayout } from "../../components/layout/MainLayout";
import { Blogs } from "./components/Blogs";
import { Search } from "react-feather";
import Prison from "../../assets/prisoners/inmate5.png";
import { PageHeader } from "../../components/layout/components/PageHeader";

export const Blog = (page = "blog") => {
  const [activeFilter, setActiveFilter] = useState("Latest");
  const postsQuery = [
    {
      image_url: Prison,
      author: "John Arthur",
      title: "Prisoners stories about transformation",
      date: "Wed 12, Jul",
      desc: "Committed to be a Christ centered;Committed to be a Christ centered;Committed to be a Christ centered; ministry reconciling prisoners ex-prisoners and their families with God and victims of crime for the transformation and restoration of hope to all those involved and affected by crime within and outside Uganda",
    },
    {
      image_url: Prison,
      author: "Katusiime Sarah",
      title: "The life in prison as a political activist",
      date: "Wed 12, Jul",
      desc: "Committed to be a Christ centered;Committed to be a Christ centered;Committed to be a Christ centered; ministry reconciling prisoners ex-prisoners and their families with God and victims of crime for the transformation and restoration of hope to all those involved and affected by crime within and outside Uganda",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <MainLayout page="blog">
      <PageHeader title="Blog" />
      <div className="my-8 space-y-8 px-8 lg:px-24">
        <div className="space-y-8 2xl:w-[1280px] 2xl:mx-auto">
          {/* Filter section */}
          <div className="flex flex-col md:flex-row items-center justify-between space-y-8">
            <div className="w-full md:w-7/12 flex space-x-4">
              <button
                className={
                  activeFilter === "Latest"
                    ? "font-bold underline underline-offset-8 decoration-red-700 decoration-2"
                    : "font-semibold"
                }
                onClick={() => setActiveFilter("Latest")}
              >
                Latest Articles
              </button>
              <button
                className={
                  activeFilter === "Archives"
                    ? "font-bold underline decoration-2 decoration-red-700 underline-offset-8"
                    : "font-semibold"
                }
                onClick={() => setActiveFilter("Archives")}
              >
                Archives
              </button>
            </div>
            <div className="w-full md:w-4/12 flex space-x-4">
              <input
                placeholder="Search for blog here ..."
                className="p-2 w-full rounded-lg border border-black"
              />
              <div className="bg-red-700 rounded-lg p-2 text-white">
                <Search />
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between space-y-8">
            {/* Blog post section */}
            <div className="w-full">
              {postsQuery?.map((post, index) => (
                <Blogs key={index} {...post} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
