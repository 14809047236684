export const PageHeader = ({ title }) => {
  return (
    <section className="relative bg-header bg-cover h-48 md:h-80 lg:h-[27em] w-full">
      {title ? (
        <div className="flex h-full flex-col-reverse px-8 lg:px-24 py-8">
          <div className="flex">
            <div className="bg-white w-1 h-8"></div>
            <p className="text-white font-semibold text-3xl mx-4">{title}</p>
          </div>
        </div>
      ) : (
        ""
      )}
    </section>
  );
};
