import JTLogo from "../../../assets/JTLogo.png";
import { Mail, Map, Voicemail } from "react-feather";
import Logo from "../../../assets/logo.png";
import ScrollToTop from "../../ScrollToTop";
import { Facebook, Instagram, Twitter, Youtube } from "react-feather";

export const Footer = () => {
  return (
    <footer>
      <section className="text-white bg-gray-900 px-4 md:px-8 text-sm">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 py-6">
          {/* Logo section */}
          <div className="flex items-center">
            <img src={Logo} alt=".." className="w-32" />
            <p className="text-lg font-bold">PRISON FELLOWSHIP UGANDA</p>
          </div>
          {/* About us */}
          <div className="py-2 px-6 md:p-6">
            <p className="mb-6 font-bold text-red-600 text-2xl">ABOUT US</p>
            <div className="space-y-4">
              <p>
                Prison fellowiship is a corporate Christain Ministry. It is an
                association of prison ministries od different denominations
                across the Christain faith.
              </p>
              <div className="flex space-x-2">
                <a href="..." className="bg-white rounded-full p-2 text-black">
                  <Facebook />
                </a>
                <a href=".." className="bg-white rounded-full p-2 text-black">
                  <Twitter />
                </a>
                <a href=".." className="bg-white rounded-full p-2 text-black">
                  <Youtube />
                </a>
                <a href=".." className="bg-white rounded-full p-2 text-black">
                  <Instagram />
                </a>
              </div>
            </div>
          </div>
          {/* Quick links */}
          <div className="py-2 px-6 md:p-6">
            <p className="mb-6 font-bold text-red-600 text-2xl">QUICK LINKS</p>
            <a className="hover:underline block py-2" href="/">
              What we do
            </a>
            <a className="hover:underline block py-2" href="/">
              Who we are
            </a>
            <a className="hover:underline block py-2" href="/">
              Donate or Support
            </a>
            <a className="hover:underline block py-2" href="/">
              Volunteer
            </a>
            <a className="hover:underline block py-2" href="/">
              FAQs
            </a>
          </div>
          {/* Contact us */}
          <div className="flex flex-col py-2 px-6 md:p-6">
            <p className="mb-6 font-bold text-red-600 text-2xl">CONTACT US</p>
            <div className="space-y-4">
              <div className="flex">
                {/* address */}
                <div className="mr-4">
                  <Map />
                </div>
                <p>
                  City square, Kampala, Uganda,
                  <br /> Plot 5674
                </p>
              </div>
              {/* Phone contact */}
              <div className="flex">
                <div className="mr-4">
                  <Voicemail />
                </div>
                <p>
                  +256-780-457644
                  <br /> +256-756-98644
                </p>
              </div>
              {/* email */}
              <div className="flex">
                <div className="mr-4">
                  <Mail />
                </div>
                <p>info@pfu.org</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-black text-white flex flex-col md:flex-row items-center justify-between">
        <p className="text-center p-2 text-sm">
          &copy; Copyright 2022. All rights reserved. Prison Fellowship Uganda
        </p>
        <div>
          <span className="flex flex-row items-center space-x-4">
            <p>Designed by: </p>
            <a
              href="https://jast-tech.com"
              target="_blank"
              rel="noreferrer"
              className=""
            >
              <img src={JTLogo} alt="Jastech" className="h-8" />
            </a>
          </span>
        </div>
      </section>
      <ScrollToTop />
    </footer>
  );
};
